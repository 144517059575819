import React, { Fragment } from 'react';
import classNames from 'classnames';
import ServiceItem from './service-item';
import { CaretRight, CaretDownSmall } from '../base/images';

class ServiceGroup extends React.Component {
  state = {
    collapsed: this.props.collapseGroups
  };

  componentDidUpdate(prevProps) {
    if (prevProps.showHeader && !this.props.showHeader && this.state.collapsed) {
      this.setState({ collapsed: false });
    }
  }

  toggleCollapsed = (ev) => {
    ev.preventDefault();
    this.setState(({ collapsed }) => ({ collapsed: !collapsed }));
  };

  render() {
    const { collapsed } = this.state;
    const {
      name, services, showHeader, alwaysShowDescription, multipleServices,
      selectedServices, availableServiceIds, bookableServiceIds
    } = this.props;
    const selectedCount = services.filter(s => selectedServices?.has(s.get('serviceId'))).size;

    const classNameGroup = classNames({
      'cb-service-group': true,
      'cb-service-group-single': !showHeader,
      collapsed
    });

    return (
      <div className={classNameGroup}>
        {showHeader && (
          <div className="cb-service-group-header" onClick={this.toggleCollapsed}>
            {collapsed ? <CaretRight /> : <CaretDownSmall />}

            <span className="cb-service-group-name">{name}</span>

            {multipleServices && selectedCount > 0 && (
              <span className="cb-service-group-size">
                {selectedCount}
              </span>
            )}
          </div>
        )}
        <div className="cb-service-list">
          {!collapsed && services.map((service, index) => {
            const serviceId = service.get('serviceId');

            return (
              <Fragment key={serviceId}>
                {index !== 0 && <hr />}

                <ServiceItem
                  key={serviceId}
                  service={service}
                  selected={selectedServices && selectedServices.has(serviceId)}
                  available={availableServiceIds.includes(serviceId)}
                  bookable={bookableServiceIds.includes(serviceId)}
                  multipleServices={multipleServices}
                  alwaysShowDescription={alwaysShowDescription}
                  onSelect={() => this.props.serviceSelected(service)}
                />
              </Fragment>
            );
          })}
        </div>
      </div>
    );
  }
}

export default ServiceGroup;
