import React, { Fragment } from 'react';
import classNames from 'classnames';
import ResourceItem from './resource-item';
import { CaretRight, CaretDownSmall } from '../base/images';

class ResourceGroup extends React.Component {
  state = {
    collapsed: this.props.collapseGroups
  };

  componentDidUpdate(prevProps) {
    if (prevProps.showHeader && !this.props.showHeader && this.state.collapsed) {
      this.setState({ collapsed: false });
    }
  }

  toggleCollapsed = (ev) => {
    ev.preventDefault();
    this.setState(({ collapsed }) => ({ collapsed: !collapsed }));
  };

  render() {
    const { collapsed } = this.state;
    const { name, showHeader, resources, resourceSelected } = this.props;

    const classNameGroup = classNames({
      'cb-resource-group': true,
      'cb-resource-group-single': !showHeader,
      collapsed
    });

    return (
      <div className={classNameGroup}>
        {showHeader && (
          <h3 className="cb-resource-group-header" onClick={this.toggleCollapsed}>
            {collapsed ? <CaretRight /> : <CaretDownSmall />}

            <span className="cb-resource-group-name">{name}</span>
          </h3>
        )}
        <div className="cb-resource-list">
          {!collapsed && resources.map((resource, index) => {
            return (
              <Fragment key={resource.get('id')}>
                {index !== 0 && <hr />}

                <ResourceItem
                  resource={resource}
                  onSelect={() => resourceSelected(resource)}
                />
              </Fragment>
            );
          })}
        </div>
      </div>
    );
  }
}

export default ResourceGroup;
